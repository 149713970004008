import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index.js';

import vuetify from './vuetify.config.mjs';

import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import { initializeFirebaseMessaging } from './firebase';
(async () => {
  const { Quasar } = await import('quasar');
  const quasarUserOptions = await import('./quasar-user-options.js');

  const app = createApp(App);

  app.use(router)
    .use(vuetify)
    .use(Quasar, quasarUserOptions.default);
  setupCalendar(app, {});

  app.component('VCalendar', Calendar);
  app.component('VDatePicker', DatePicker);

  // tato funkcionalita se zatim programuje.
  // Inicializace Firebase Messaging
 // const messaging = await initializeFirebaseMessaging();
 // app.config.globalProperties.$messaging = messaging;

  app.mount('#app');
})();