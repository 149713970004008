<template>
  <div :class="{ 'my-card': true, 'dark-mode': isDarkMode }">
 
  <v-container>

    <!-- První řada selectů -->
    <v-row>
      <v-col cols="6">
      <v-combobox
          v-model="selectedProcessors"
          :items="[allOption, ...processors]"
          item-title="name"
          item-value="id"
          label="Filtrovat dle zpracovatelů"
          return-object
          @input="filterData('assignedId')"
          multiple
          chips
          deletable-chips
          :class="{ 'dark-mode-combobox': isDarkMode }"
        ></v-combobox>
      </v-col>
      <v-col cols="6">
   <!--   <v-combobox
    v-model="selectedTeams"
    :items="[allTeamsOption, ...teams]"
    item-title="groupname"
    item-value="id"
    label="Filtrovat dle skupin"
    return-object
    @input="filterData('teamId')"
    multiple
    chips
    deletable-chips
    :class="{ 'dark-mode-combobox': isDarkMode }"
  ></v-combobox>-->
      </v-col>
    </v-row>

    <!-- Druhá řada selectů -->
    <v-row>
      <v-col cols="6">
           <span :class="{ 'date-formatted': true, 'dark-mode-text': isDarkMode }">Od {{ formattedFromDate }}</span>
        <VDatePicker
          v-model="from"
          label="Datum OD"
          @change="filterData('from')"
          style="position: absolute; z-index: 1000;"
        >
          <template #value="{ date }"></template>
          <template #default="{ togglePopover }">
            <v-btn   @click="togglePopover">Datum OD</v-btn>
          </template>
        </VDatePicker>
      </v-col>
      <v-col cols="6">
          <span :class="{ 'date-formatted': true, 'dark-mode-text': isDarkMode }">Do {{ formattedToDate }}</span>
        <VDatePicker
          v-model="to"
          label="Datum DO"
          @change="filterData('to')"
          style="position: absolute; z-index: 1000;"
        >
          <template #value="{ date }"></template>
          <template #default="{ togglePopover }">
            <v-btn @click="togglePopover">Datum DO</v-btn>
          </template>
        </VDatePicker>
      </v-col>
    </v-row>
  </v-container>
    

      <!-- Tlačítko pro potvrzení filtru -->
      <v-col cols="12">
        <v-btn @click="applyFilter" class="filter-button" rounded>Filtrovat</v-btn>
      </v-col>

    
  </div>
</template>

<style scoped>
.my-card {
  border-radius: 20px;
  padding: 16px;
  z-index: 1;
  background-color: #ffffff; /* světlá barva pozadí */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 80%; /* Set the desired width */
  transition: background-color 0.3s ease; /* plynulý přechod barvy pozadí */
}

.date-formatted {
  font-size: 20px;
  margin: 20px;
}

.dark-mode .my-card {
  background-color: #444; /* tmavá barva pozadí */
}

.dark-mode .dark-mode-text {
  color: #fff; /* bílá barva textu v tmavém režimu */
}

.dark-mode .filter-button {
  background-color: #4CAF50; /* zelená barva tlačítka ve tmavém režimu */
  color: #fff; /* bílá barva textu na tlačítku ve tmavém režimu */
}

.dark-mode-combobox {
  background-color: #444; /* Dark background color */
  color: #fff; /* Text color */
}
</style>

<script>
import axios from 'axios';

export default {
  props: {
    isDarkMode: {
      type: Boolean,
      default: false,
    },
      
  
  },
  data() {
    return {
      selectedProcessors: [],
      processors: [],
      selectedTeams: [],
      teams: [],
      from: null,
      to: null,
      date: null,
      allOption: { id: null, name: 'Vše' }, // Option for processors
      allTeamsOption: { id: null, groupname: 'Vše' }, // Option for teams
    };
  },
  computed: {
    formattedFromDate() {
      if (!this.from) return '';
      const options = { day: '2-digit', month: 'long' };
      return this.from.toLocaleDateString('cs-CZ', options);
    },
    formattedToDate() {
      if (!this.to) return '';
      const options = { day: '2-digit', month: 'long' };
      return this.to.toLocaleDateString('cs-CZ', options);
    },
  },
  methods: {
  filterData(filterType) {
    this.$emit('filter-changed', {
      from: this.from,
      to: this.to,
      assigned_id: this.selectedProcessors.map((item) => item.id),
      team_id: this.selectedTeams.map((item) => item.id), // Přidání team_id do parametrů
      type: filterType,
    });

      // Store filter values in localStorage
localStorage.setItem(
  'filterData',
  JSON.stringify({
    from: this.from ? this.from.toISOString() : null,
    to: this.to ? this.to.toISOString() : null,
    assigned_id: this.selectedProcessors.map((item) => item.id),
    team_id: this.selectedTeams.map((item) => item.id),
  })
);

    },
    applyFilter() {
      this.filterData('assignedId');
    },
    async fetchProcessors() {
      try {
        const response = await axios.get('https://betatodo.oznamzde.cz/api/admin-users');
        this.processors = response.data.admin_users;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchTeams() {
      try {
        const response = await axios.get('https://betatodo.oznamzde.cz/api/getAllTeams');
        this.teams = response.data.teams;
      } catch (error) {
        console.error(error);
      }
    },
  },
async created() {
  // Retrieve filter values from localStorage
  const filterData = localStorage.getItem('filterData');
  if (filterData) {
    const { from, to, assigned_id, team_id } = JSON.parse(filterData);

    this.from = from ? new Date(from) : null;
    this.to = to ? new Date(to) : null;

    this.selectedProcessors = this.processors.filter((item) => assigned_id.includes(item)) || [];
    this.selectedTeams = this.teams.filter((item) => team_id.includes(item)) || [];
  }

  await this.fetchProcessors();
  await this.fetchTeams();
},
};
</script>
