<template>
  <div>
    <!-- SIDEBAR: zobrazí se hned, pokud víme, že user není na /prihlaseni a existuje google_id -->
    <Sidebar
      v-if="shouldShowSidebar"
      :isDarkMode="isDarkMode"
      @toggle-dark-mode="toggleDarkMode"
    />

    <v-app :class="{ 'dark-mode': isDarkMode }">
      <v-main>
        <!-- Overlay jen přes hlavní obsah (router-view) -->
        <v-overlay :value="!isContentReady" absolute>
          <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
        </v-overlay>

        <!-- Router-view => hlavní obsah -->
        <router-view :isDarkMode="isDarkMode" />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router'; 
import Sidebar from './components/admin/Sidebar.vue';

export default {
  name: 'App',
  components: {
    Sidebar
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    // Tmavý režim
    const isDarkMode = ref(JSON.parse(localStorage.getItem('isDarkMode')) || false);
    const toggleDarkMode = () => {
      isDarkMode.value = !isDarkMode.value;
      localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode.value));
    };

    // Zda je právě /prihlaseni
    const isLoginPage = computed(() => route.path === '/prihlaseni');

    // Určuje, zda obsah (router-view) už můžeme zobrazit
    const isContentReady = ref(false);

    // Určuje, zda zobrazit Sidebar (např. pokud existuje google_id)
    const showSidebar = ref(false);

    // Pomocná computed pro V-IF v šabloně
    // Zobrazíme Sidebar, jen když NENÍ login page a je "povolen" (máme google_id atd.)
    const shouldShowSidebar = computed(() => {
      return !isLoginPage.value && showSidebar.value;
    });

    // Zkontroluje platnost google_id na backendu
    const checkGoogleId = async () => {
      const googleId = localStorage.getItem('google_id');
      if (!googleId) {
        // Pokud vůbec není uložen, rovnou redirect
        router.replace('/prihlaseni');
        return;
      }

      // Lokálně existuje => Sidebar zobrazíme hned (v onMounted)
      // Teď ale asynchronně zkontrolujeme platnost na serveru
      try {
        const response = await fetch('https://betatodo.oznamzde.cz/api/v2/check-google-id', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ google_id: googleId })
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error('Chyba při kontrole google_id:', errorText);
          router.replace('/prihlaseni');
          return;
        }

        const data = await response.json();
        if (!data.exists) {
          localStorage.removeItem('google_id');
          router.replace('/prihlaseni');
        } else {
          // Platný google_id => můžeme zobrazit obsah
          isContentReady.value = true;
        }
      } catch (error) {
        console.error('Chyba při kontrole google_id:', error);
        router.replace('/prihlaseni');
      }
    };

    // Inicializace
    onMounted(async () => {
      // Nastavíme tmavý režim dle localStorage
      isDarkMode.value = JSON.parse(localStorage.getItem('isDarkMode')) || false;

      // Když je user na /prihlaseni, nepotřebujeme nic validovat
      // => jen vypneme overlay, ať vidí login stránku
      if (isLoginPage.value) {
        isContentReady.value = true;
        return;
      }

      // Pokud user NENÍ na /prihlaseni, zkusíme zda existuje google_id
      const googleId = localStorage.getItem('google_id');
      if (!googleId) {
        // Není -> pryč
        router.replace('/prihlaseni');
        return;
      }

      // Pokud v localStorage je google_id, zobrazíme Sidebar hned,
      // aby se "neproblikával" až po dokončení requestu
      showSidebar.value = true;

      // Mezitím asynchronně ověřujeme, zda google_id platí
      await checkGoogleId();
    });

    // Reagujeme na změnu routy (přechod z/do /prihlaseni)
    watch(
      () => route.path,
      async (newPath, oldPath) => {
        // Pokud jdeme na /prihlaseni:
        if (newPath === '/prihlaseni') {
          // Umožníme zobrazit přihlašovací formulář (bez overlay)
          isContentReady.value = true;
          showSidebar.value = false;
          return;
        }

        // Pokud jdeme z /prihlaseni jinam, musíme znovu zvalidovat google_id
        // a do té doby overlay, atd.
        isContentReady.value = false;
        showSidebar.value = false;

        const googleId = localStorage.getItem('google_id');
        if (!googleId) {
          router.replace('/prihlaseni');
        } else {
          // Okamžitě zobrazíme sidebar
          showSidebar.value = true;
          // A znovu ověříme
          await checkGoogleId();
        }
      }
    );

    return {
      isDarkMode,
      toggleDarkMode,
      isContentReady,
      showSidebar,
      shouldShowSidebar,
      isLoginPage
    };
  }
};
</script>

<style scoped>
.dark-mode {
  background-color: #333;
  color: #fff;
}
</style>
